import React from "react";
import btn_start from "../assets/btn_start.png";
import background from "../assets/background.png";
import instructions from "../assets/instructions.png";

interface StartScreenProps {
  onStart: () => void;
}

const StartScreen: React.FC<StartScreenProps> = ({ onStart }) => {
  return (
    <div
      className="h-full w-full flex flex-col items-center justify-between bg-cover bg-center"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="w-full flex justify-center pt-20">
        <button
          onClick={onStart}
          className="bg-transparent border-none cursor-pointer focus:outline-none w-64"
        >
          <img src={btn_start} alt="Start Game" className="w-full" />
        </button>
      </div>
      <div className="w-full flex justify-center pb-10">
        <img src={instructions} alt="Instructions" className="w-[90%]" />
      </div>
    </div>
  );
};

export default StartScreen;
