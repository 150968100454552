import React, { useState, useEffect } from "react";
import Game from "./components/Game";
import StartScreen from "./components/StartScreen";
import GameOver from "./components/GameOver";
import { initAudio } from "./utils/audioManager";

const useViewportHeight = () => {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateHeight = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", updateHeight);
    // Một số trình duyệt di động cần thêm sự kiện 'orientationchange'
    window.addEventListener("orientationchange", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
      window.removeEventListener("orientationchange", updateHeight);
    };
  }, []);

  return height;
};

const App: React.FC = () => {
  const [gameState, setGameState] = useState<"start" | "playing" | "ended">(
    "start"
  );
  const [score, setScore] = useState(0);
  const viewportHeight = useViewportHeight();

  useEffect(() => {
    initAudio();
  }, []);

  const handleStartGame = () => {
    setGameState("playing");
    setScore(0);
  };

  const handleGameOver = (finalScore: number) => {
    setScore(finalScore);
    setGameState("ended");
  };

  const handlePlayAgain = () => {
    setGameState("start");
    setScore(0);
  };

  return (
    <div
      className="flex items-center justify-center bg-gray-100"
      style={{ height: `${viewportHeight}px` }}
    >
      <div
        className="w-full h-full md:w-[390px] md:h-[844px] bg-white shadow-lg overflow-hidden relative"
        style={{ maxHeight: `${viewportHeight}px` }}
      >
        {gameState === "start" && <StartScreen onStart={handleStartGame} />}
        {(gameState === "playing" || gameState === "ended") && (
          <>
            <Game onGameOver={handleGameOver} />
            {gameState === "ended" && (
              <GameOver score={score} onRestart={handlePlayAgain} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default App;
