import React, { useEffect, useRef, useState } from "react";
import Phaser from "phaser";
import MainScene from "../scenes/MainScene";

interface GameProps {
  onGameOver: (score: number) => void;
}

const DESIGN_WIDTH = 1238;
const DESIGN_HEIGHT = 2657;

const calculateGameSize = (): [number, number, number] => {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  const designRatio = DESIGN_WIDTH / DESIGN_HEIGHT;
  const screenRatio = screenWidth / screenHeight;

  let gameWidth, gameHeight, scale;

  if (screenRatio > designRatio) {
    // Screen is wider than design ratio
    gameHeight = screenHeight;
    gameWidth = screenWidth;
    scale = screenHeight / DESIGN_HEIGHT;
  } else {
    // Screen is taller than or equal to design ratio
    gameWidth = screenWidth;
    gameHeight = screenHeight;
    scale = screenWidth / DESIGN_WIDTH;
  }

  return [gameWidth, gameHeight, scale];
};

const Game: React.FC<GameProps> = ({ onGameOver }) => {
  const gameRef = useRef<HTMLDivElement>(null);
  const [gameSize, setGameSize] = useState(calculateGameSize());
  const gameInstance = useRef<Phaser.Game | null>(null);
  const isGameOverRef = useRef(false);

  useEffect(() => {
    if (gameRef.current && !gameInstance.current) {
      const [width, height, scale] = gameSize;

      const config: Phaser.Types.Core.GameConfig = {
        type: Phaser.AUTO,
        width,
        height,
        parent: gameRef.current,
        scene: [MainScene],
        physics: {
          default: "arcade",
          arcade: {
            gravity: { x: 0, y: 300 },
            debug: false,
          },
        },
        scale: {
          mode: Phaser.Scale.NONE,
          width,
          height,
        },
      };

      gameInstance.current = new Phaser.Game(config);

      gameInstance.current.events.on("gameOver", (score: number) => {
        if (!isGameOverRef.current) {
          isGameOverRef.current = true;
          onGameOver(score);
        }
      });

      // Pass design info to the scene
      gameInstance.current.scene.start("MainScene", {
        designWidth: DESIGN_WIDTH,
        designHeight: DESIGN_HEIGHT,
        scale,
      });

      const resizeGame = () => {
        const [newWidth, newHeight, newScale] = calculateGameSize();
        setGameSize([newWidth, newHeight, newScale]);
        if (gameInstance.current) {
          gameInstance.current.scale.resize(newWidth, newHeight);
          const mainScene = gameInstance.current.scene.getScene(
            "MainScene"
          ) as Phaser.Scene;
          if (mainScene && mainScene.events) {
            mainScene.events.emit("resize", {
              width: newWidth,
              height: newHeight,
              scale: newScale,
            });
          }
        }
      };

      window.addEventListener("resize", resizeGame);

      return () => {
        window.removeEventListener("resize", resizeGame);
        if (gameInstance.current) {
          gameInstance.current.destroy(true);
          gameInstance.current.events.off("gameOver", onGameOver);
        }
      };
    }
  }, []);

  return (
    <div
      id="game"
      ref={gameRef}
      style={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
      }}
    />
  );
};

export default Game;
