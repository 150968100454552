import Phaser from "phaser";
import { Types } from "phaser";
import backgroundImg from "../assets/background.png";
import cloudImg from "../assets/cloud.png";
import cloud2Img from "../assets/cloud_2.png";
import characterImg from "../assets/character.png";
import hitboxImg from "../assets/hitbox.png";
import moonImg from "../assets/moon.png";
import coinImg from "../assets/coin.png";
import { getAudioLevel } from "../utils/audioManager";
import coinSoundFile from "../assets/sounds/snd-6.wav";
import winnerSoundFile from "../assets/sounds/snd-winner.mp3";
import overSoundFile from "../assets/sounds/snd-game-over.mp3";

interface CoinImage extends Phaser.GameObjects.Image {
  posY: number;
  posX: number;
}

export default class MainScene extends Phaser.Scene {
  private player!: Phaser.GameObjects.Image;
  private playerHitbox!: Phaser.Physics.Arcade.Sprite;
  private platforms!: Phaser.Physics.Arcade.StaticGroup;
  private coins!: Phaser.GameObjects.Group;
  private moon!: any;
  private score: number = 0;
  private scoreText!: Phaser.GameObjects.Text;
  private volumeText!: Phaser.GameObjects.Text;
  private designWidth!: number;
  private designHeight!: number;
  private scaleRatio!: number;
  private isPlayerJumping: boolean = false;

  private lastPlatformX: number = 0;
  private isGameEnding: boolean = false;

  private coinSound!: Phaser.Sound.BaseSound;
  private winnerSound!: Phaser.Sound.BaseSound;
  private overSound!: Phaser.Sound.BaseSound;

  private TOTAL_ITEM: number = 12;

  constructor() {
    super("MainScene");
  }

  init(data: { designWidth: number; designHeight: number; scale: number }) {
    this.designWidth = data.designWidth;
    this.designHeight = data.designHeight;
    this.scaleRatio = data.scale;
    this.scaleRatio = this.scaleRatio - (this.scaleRatio * 25) / 1000;

    this.events.on("resize", this.resize, this);
  }

  preload() {
    this.load.image("background", backgroundImg);
    this.load.image("cloud", cloudImg);
    this.load.image("cloud_2", cloud2Img);
    this.load.image("character", characterImg);
    this.load.image("hitbox", hitboxImg);
    this.load.image("coin", coinImg);
    this.load.image("moon", moonImg);
    this.load.audio("coinSound", coinSoundFile);
    this.load.audio("winnerSound", winnerSoundFile);
    this.load.audio("overSound", overSoundFile);
  }

  create() {
    this.coinSound = this.sound.add("coinSound");
    this.winnerSound = this.sound.add("winnerSound");
    this.overSound = this.sound.add("overSound");

    this.createBackground();
    this.createUI();
    this.createPlatforms();
    this.createPlayer();
    this.createCoins();
    this.createPhysics();
  }

  private createBackground() {
    const background = this.add.image(
      this.cameras.main.width / 2,
      this.cameras.main.height / 2,
      "background"
    );
    const scaleX = this.cameras.main.width / background.width;
    const scaleY = this.cameras.main.height / background.height;
    const scale = Math.max(scaleX, scaleY);
    background.setScale(scale).setScrollFactor(0);
  }

  private createPlatforms() {
    this.platforms = this.physics.add.staticGroup();
    const gameHeight = this.cameras.main.height;
    let x = this.cameras.main.width / 2;
    let y = gameHeight / 2;

    for (let i = 0; i < this.TOTAL_ITEM + 1; i++) {
      if (i === this.TOTAL_ITEM) {
        // Mặt trăng ở vị trí cuối cùng

        this.moon = this.platforms
          .create(x, y, "moon")
          .setScale(this.scaleRatio - (this.scaleRatio * 30) / 100);
        this.moon.body.updateFromGameObject();
        this.moon.y = gameHeight / 2 - (this.moon.width * this.moon.scale) / 2;
      } else {
        const cloud = this.platforms
          .create(x, y, i % 2 == 0 ? "cloud" : "cloud_2")
          .setScale(this.scaleRatio);
        cloud.body.updateFromGameObject();
      }

      const lastCloud = this.platforms.getChildren()[
        this.platforms.getChildren().length - 1
      ] as Phaser.Physics.Arcade.Sprite;

      // Cập nhật vị trí cho phần tử tiếp theo
      x =
        lastCloud.x +
        lastCloud.width * this.scaleRatio +
        Phaser.Math.Between(80, 150);
      if (i < 20) {
        y = lastCloud.y + Phaser.Math.Between(-200, 200);
        // Đảm bảo y nằm trong khoảng hợp lý
        y = Phaser.Math.Clamp(y, gameHeight / 4, (gameHeight * 3) / 4);
      }
    }

    this.lastPlatformX = x; // Lưu vị trí x của phần tử cuối cùng
  }

  private createPlayer() {
    const startCloud =
      this.platforms.getChildren()[0] as Phaser.Physics.Arcade.Sprite;
    this.playerHitbox = this.physics.add
      .sprite(startCloud.x, startCloud.y - 150, "hitbox")
      .setScale(this.scaleRatio);
    this.playerHitbox.setCollideWorldBounds(true);
    this.playerHitbox.setBounce(0.2);

    // Tạo player như một hình ảnh bình thường
    this.player = this.add
      .image(this.playerHitbox.x - 14, this.playerHitbox.y, "character")
      .setScale(this.scaleRatio);
  }

  private createCoins() {
    this.coins = this.add.group();
    this.platforms
      .getChildren()
      .forEach((child: Phaser.GameObjects.GameObject) => {
        if (child instanceof Phaser.Physics.Arcade.Sprite) {
          const platform = child;
          if (
            platform.texture.key === "cloud" ||
            platform.texture.key === "cloud_2"
          ) {
            const coin = this.createCoinAbovePlatform(platform);
            this.coins.add(coin);
          }
        }
      });
  }

  private createCoinAbovePlatform(
    platform: Phaser.Physics.Arcade.Sprite
  ): CoinImage {
    const moveY = Phaser.Math.Between(50, 200);
    const coin = this.add.image(
      platform.x,
      platform.y - moveY,
      "coin"
    ) as CoinImage;
    coin.posY = platform.y - moveY;
    coin.posX = platform.x;
    coin.setScale(this.scaleRatio * 0.5);
    return coin;
  }

  private createPhysics() {
    this.physics.add.collider(this.playerHitbox, this.platforms);
    //this.physics.add.collider(this.coins, this.platforms);
    this.physics.add.overlap(
      this.playerHitbox,
      this.coins,
      undefined,
      undefined,
      this
    );
  }

  private createUI() {
    const fontSize = Math.max(30, Math.floor(32 * this.scaleRatio));
    this.scoreText = this.add.text(this.cameras.main.width - 10, 10, "0000", {
      fontSize: `${fontSize}px`,
      fontFamily: "'1FTV Goodrace', Arial, sans-serif",
      color: "#FFFFFF",
      align: "right",
    });
    this.scoreText.setOrigin(1, 0); // Đặt điểm neo sang phải
    this.scoreText.setScrollFactor(0);

    this.volumeText = this.add.text(10, 10, "0000", {
      fontSize: `${fontSize}px`,
      fontFamily: "'1FTV Goodrace', Arial, sans-serif",
      color: "#FFFFFF",
      align: "left",
    });
    this.volumeText.setOrigin(0, 0); // Đặt điểm neo sang phải
    this.volumeText.setScrollFactor(0);

    this.time.delayedCall(1000, () => {
      this.updateUIFont();
    });
  }

  private updateUIFont() {
    const fontSize = Math.max(30, Math.floor(32 * this.scaleRatio));
    this.scoreText.setStyle({
      fontSize: `${fontSize}px`,
      fontFamily: "'1FTV Goodrace', Arial, sans-serif",
      color: "#FFFFFF",
      align: "right",
    });

    this.volumeText.setStyle({
      fontSize: `${fontSize}px`,
      fontFamily: "'1FTV Goodrace', Arial, sans-serif",
      color: "#FFFFFF",
      align: "left",
    });
  }

  update() {
    if (this.isGameEnding) return;

    const audioLevel = getAudioLevel();
    this.volumeText.setText(Math.round(audioLevel).toString());

    if (audioLevel >= 20 && audioLevel <= 26) {
      this.movePlatforms(-5 * this.scaleRatio);
    } else if (audioLevel > 26 && audioLevel <= 100) {
      //if (!this.isPlayerJumping && this.playerHitbox.body) {
      if (this.playerHitbox.body) {
        this.isPlayerJumping = true;
        const jumpHeight =
          Phaser.Math.Linear(-300, -1200, (audioLevel - 26) / 74) *
          this.scaleRatio;
        this.playerHitbox.setVelocityY(jumpHeight);
      }
      const moveX =
        Phaser.Math.Linear(-5, -20, (audioLevel - 26) / 74) * this.scaleRatio;
      this.movePlatforms(moveX);
    } else {
      this.isPlayerJumping = false;
    }
    // Check if player is on a platform
    if (this.playerHitbox.body) {
      const touchingDown = this.playerHitbox.body.touching.down;
      if (touchingDown) {
        this.isPlayerJumping = false;
      }
    }

    // Update player position based on hitbox
    this.player.x = this.playerHitbox.x - 14;
    this.player.y = this.playerHitbox.y;

    // Game over condition

    if (
      Math.round(
        this.playerHitbox.y +
          (this.playerHitbox.height * this.playerHitbox.scale) / 2
      ) >= this.cameras.main.height
    ) {
      this.gameOver();
    }

    // Win condition
    if (this.moon.x <= this.cameras.main.width / 2) {
      this.gameWin();
    }

    this.coins.getChildren().forEach((child: Phaser.GameObjects.GameObject) => {
      const coin = child as CoinImage;
      if (
        Phaser.Geom.Intersects.RectangleToRectangle(
          this.playerHitbox.getBounds(),
          coin.getBounds()
        )
      ) {
        this.collectCoin(coin);
      }
    });

    this.platforms
      .getChildren()
      .forEach((child: Phaser.GameObjects.GameObject) => {
        if (child instanceof Phaser.Physics.Arcade.Sprite) {
          if (child.texture.key === "moon") {
            if (
              Phaser.Geom.Intersects.RectangleToRectangle(
                this.playerHitbox.getBounds(),
                child.getBounds()
              )
            ) {
              this.gameWin();
            }
          }
        }
      });
    this.scoreText.setText(this.d4(this.score));
  }

  private movePlatforms(speed: number) {
    if (this.isGameEnding) return; // Không di chuyển nếu game đang kết thúc

    this.platforms
      .getChildren()
      .forEach((child: Phaser.GameObjects.GameObject) => {
        if (child instanceof Phaser.Physics.Arcade.Sprite) {
          child.x += speed;
          child.body?.updateFromGameObject();
        }
      });

    this.lastPlatformX += speed;

    // Kiểm tra nếu phần tử cuối cùng đã đến giữa màn hình
    if (this.lastPlatformX <= this.cameras.main.width / 2) {
      this.endGame();
    }

    // Di chuyển các đồng xu cùng với platforms

    this.coins.getChildren().forEach((child: Phaser.GameObjects.GameObject) => {
      const coin = child as CoinImage;
      coin.x += speed;
    });
  }

  private endGame() {
    // Hiển thị thông báo chiến thắng hoặc kết thúc game
    this.gameWin();
  }

  private collectCoin(coin: CoinImage) {
    // Ngăn không cho thu thập đồng xu nhiều lần
    if (!coin.visible) return;

    // Tăng điểm số
    this.score += 5;
    this.coinSound.play();

    // Tạo hiệu ứng "nổ"
    this.createCoinExplosionEffect(coin.x, coin.y);

    // Ẩn đồng xu gốc
    coin.setVisible(false);

    // Đặt lại vị trí của đồng xu sau một khoảng thời gian
    // this.time.delayedCall(1000, () => {
    //   coin.x = this.cameras.main.width + coin.width;
    //   const moveY = Phaser.Math.Between(50, 200);
    //   coin.y = coin.posY - moveY;
    //   coin.setVisible(true);
    // });
  }

  private createCoinExplosionEffect(x: number, y: number) {
    const particleCount = 5;
    for (let i = 0; i < particleCount; i++) {
      const particle = this.add.image(x, y, "coin").setScale(0.3);

      this.tweens.add({
        targets: particle,
        x: x + Phaser.Math.Between(-50, 50),
        y: y + Phaser.Math.Between(-50, 50),
        scale: 0,
        alpha: 0,
        duration: 500,
        ease: "Power2",
        onComplete: () => {
          particle.destroy();
        },
      });
    }
  }

  private gameOver() {
    this.isGameEnding = true;
    this.scene.pause();
    this.physics.pause();
    this.tweens.pauseAll();
    this.overSound.play();
    this.game.events.emit("gameOver", this.score);
  }

  private gameWin() {
    this.isGameEnding = true;
    this.score += 50;
    this.scene.pause();
    this.physics.pause();
    this.tweens.pauseAll();
    this.winnerSound.play();
    this.game.events.emit("gameOver", this.score);
  }

  private resize(data: { width: number; height: number; scale: number }) {
    this.scaleRatio = data.scale;
    this.cameras.resize(data.width, data.height);
    this.createBackground();
    this.repositionGameObjects();
  }

  private repositionGameObjects() {
    // Reposition and rescale all game objects here
    // This method needs to be implemented based on your specific game objects
  }

  private scaleX(x: number): number {
    return x * this.scaleRatio;
  }

  private scaleY(y: number): number {
    return y * this.scaleRatio;
  }

  private d4(i: number): string {
    if (i < 10) {
      return "000" + i.toString();
    } else if (i < 100) {
      return "00" + i.toString();
    } else if (i < 1000) {
      return "0" + i.toString();
    } else {
      return i.toString();
    }
  }
}
