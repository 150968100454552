import React from "react";
import btn_start from "../assets/btn_reply.png";

interface GameOverProps {
  score: number;
  onRestart: () => void;
}

const GameOver: React.FC<GameOverProps> = ({ score, onRestart }) => {
  const d4 = (i: number) => {
    if (i < 10) {
      return "000" + i.toString();
    } else if (i < 100) {
      return "00" + i.toString();
    } else if (i < 1000) {
      return "0" + i.toString();
    } else {
      return i.toString();
    }
  };

  return (
    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="p-8 rounded-lg text-center text-[#FFFFFF]">
        <h2 className="text-4xl mb-4 font-Goodrace">CHÚC MỪNG!</h2>
        <h2 className="text-3xl mb-4 font-Goodrace">SỐ ĐIỂM CỦA BẠN LÀ:</h2>
        <p className="text-8xl mb-4 font-Goodrace">{d4(score)}</p>
        <button
          onClick={onRestart}
          className="absolute bottom-10 w-[90%] left-1/2 transform -translate-x-1/2"
        >
          <img src={btn_start} alt="Restart" className="w-full" />
        </button>
      </div>
    </div>
  );
};

export default GameOver;
